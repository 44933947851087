import React, {useEffect, useState} from "react";
import moment from 'moment';
import 'moment/locale/da';
import NoMilk from "./NoMilk";
import NoGluten from "./NoGluten";

const Day = (props) => {

  const [show, setShow] = useState([]);
  useEffect(() => {
    setShow(props.dayindex === moment().weekday());
  }, [props.dayindex]);

  const flipShow = () => {
    setShow(!show);
  }
  return (
    <div className={`rounded-md mb-2 overflow-hidden ${show ? 'active' : 'uprolled'}`}>
      <div className={`p-2 panel-heading  font-bold  ${show ? 'bg-blue-500' : 'bg-green-500'} flex`} onClick={flipShow}>
        <div className="grow">
        {props.day.name}
        </div>
        <div>
        </div>
      </div>
      <div>
        {props.day.courses.map((menu, index) => {
          const allergyRegex =  /\(([L/G]*)\)/;
          const results = allergyRegex.exec(menu);

          let showNoGluten = false;
          let showNoMilk = false;

          if(results != null) {
            showNoMilk = results[1].includes('L')
            showNoGluten = results[1].includes('G')

            menu = menu.replace(allergyRegex, '');
          }

          const menuItems = menu.split(':');
          return (
            <div
              className="py-2 pl-3 panel-block border-t flex flex-row border-gray text-white font-medium  hover:text-white hover:bg-gray-600/90 bg-gray-600/70"
              key={`menuItem_${index}`}>
              <div className='grow'><strong>{menuItems[0]}:</strong>{menuItems[1]}</div>
              <div className="float-right mr-2 inline-block">
                {showNoMilk && <NoMilk/>}&nbsp;
                {showNoGluten && <NoGluten/>}
                </div>
            </div>)
        })}
      </div>
    </div>
  )

}

export default Day;
